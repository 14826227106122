import React from 'react';
import './Profile.css';
import HomePage from '../HomePage/Home';

const Profile = () => {
  return (
    <div>
      <HomePage/>
    </div>
  )
}

export default Profile;
